import { apolloClient } from "@/vue-apollo";
import Vue from "vue";

function toast(txt, type) {
  if (txt === "") return;
  Vue.$toast.open({
    message: txt,
    type: type ? "success" : "warning",
    duration: 5000,
    position: "top-right",
  });
}

/**
 * Ejecuta una query GraphQL
 * @param {Object} query - La query de GraphQL
 * @param {Object} variables - Variables para la query
 * @param {Boolean} networkOnly - Si es `true`, no utiliza la cache (network-only) default false
 * @param {String} succ - Mensaje de exito
 * @param {String} err - Mensaje de error
 * @returns {Promise} - Retorna el resultado de la query
 */
export function exQue(
  query,
  variables,
  networkOnly = false,
  succ = "",
  err = ""
) {
  const dataProp = query.definitions[0].name.value;
  return apolloClient
    .query({
      query,
      variables,
      fetchPolicy: networkOnly ? "network-only" : "cache-first",
    })
    .then(({ data, loading, error }) => {
      if (error && error.length) {
        throw new Error(error);
      }
      if (!loading) {
        toast(succ, true);
        return data[dataProp];
      }

      return null;
    })
    .catch((error) => {
      toast(err, false);
      console.error("Error ejecutando query:", error);
      throw error;
    });
}

/**
 * Ejecuta una mutation GraphQL
 * @param {Object} mutation - La mutation de GraphQL
 * @param {Object} variables - Variables para la mutation
 * @param {String} succ - Mensaje de exito
 * @param {String} err - Mensaje de error
 * @returns {Promise} - Retorna el resultado de la mutation
 */
export function exMut(mutation, variables, succ = "", err = "") {
  const dataProp = mutation.definitions[0].name.value;
  console.log("dataProp", dataProp);

  return apolloClient
    .mutate({
      mutation,
      variables,
    })
    .then(({ data, errors }) => {
      if (errors && errors.length) {
        throw new Error(errors);
      }
      toast(succ, true);
      return data[dataProp];
    })
    .catch((error) => {
      toast(err, false);
      console.error("Error ejecutando mutation:", error);
      throw error;
    });
}
