<template>
  <section class="pantallas__modal">
    <div class="pantallas__top">
      <template v-if="estado == '0'">
        <h2 class="pantallas__top-title">Pantallas On-line</h2>
        <div class="pantallas__green-circle"></div>
      </template>
      <template v-if="estado == '1'">
        <h2 class="pantallas__top-title">Pantallas Off-line</h2>
        <div class="pantallas__red-circle"></div>
      </template>

      <font-awesome-icon
        @click="closeModal"
        class="pantallas__close-icon"
        icon="times"
      ></font-awesome-icon>
    </div>
    <div class="pantallas__table-container scroll-custom-550">
      <table class="pantallas__table">
        <thead class="pantallas__table-head">
          <tr class="pantallas__tr">
            <th class="pantallas__th-name">Nombre</th>
            <th class="pantallas__th text-center">Ubicación</th>
            <th class="pantallas__th text-center">Canal</th>
            <th class="pantallas__th text-center">Última conexión</th>
          </tr>
        </thead>
        <tbody class="pantallas__table-body ">
          <tr class="pantallas__tr" v-for="(pantalla, i) in pantallas" :key="i">
            <td class="pantallas__td">{{ pantalla.nombre_pantalla }}</td>
            <td class="pantallas__td text-center">{{ pantalla.ubicacion }}</td>
            <td class="pantallas__td text-center">
              {{ pantalla.canal[0].nombre_canal }}
            </td>
            <td class="pantallas__td text-center">
              {{ pantalla.fecha_conexion | fechaModal }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import moment from "moment";

export default {
  props: {
    estado: {
      type: String,
    },
    pantallas: {
      type: Array,
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },

  filters: {
    fechaModal(fecha) {
      if (!fecha) return "";
      return moment(fecha).format("DD/MM/YYYY hh:mm A");
    },
  },
};
</script>
