<template>
  <aside :class="['sidebar', {'sidebar-narrow': narrow_sidebar}]">
      <a 
        href="javascript:"
        class="sidebar-narrow-link d-none d-xl-flex" 
        :aria-label="narrow_sidebar ?'Abrir': 'Cerrar' + ' menu lateral'"
        @click="narrowSidebar">
          <font-awesome-icon :icon="narrow_sidebar ? 'chevron-right' : 'chevron-left'" />
      </a>

      <div class="sidebar-logo">
        <img class="sidebar-logo-complete" height="50px" loading="lazy" src="/imgs/twu_logo.svg" alt="Logo Linkiwork" >
        <img class="sidebar-logo-letter"   height="50px" loading="lazy" src="../assets/img/twu_letra.svg" alt="Logotipo Linkiwork">
        <a 
          class="btn sidebar-btn btn-custom btn-custom-color-white btn-block d-md-none me-3" 
          href="javascript:" 
          @click="toggleSidebar"
          role="button">
          <i class="fas fa-bars"></i>
        </a>
      </div>
      <ul :class="['sidebar-list mb-0', {'sidebar-list-show': mobile_sidebar === true}]">
        <li
          class="sidebar-list-item"
          :class="[
            path == section.moduloGeneral.ruta_admin && 'active',
            active_sidebar && path == section.moduloGeneral.route && 'active_background',
            section.nombre_modulo,
          ]"
          v-for="(section, k) in print_sections"
          :key="k"
          @click="goToRoute(section.moduloGeneral.ruta_admin)"
          @mouseover="hover_section = section"
          @mouseleave="hover_section = {}"
          v-show="checkSection(section)"
          >
          <a 
            href="javascript:"
            class="sidebar-list-item-link">
            <span  class="sidebar-list-item-link-icon">
              <img
                :content="section.nombre_modulo"
                v-tippy="{ arrow: false, placement : 'right', trigger:trigger_tippy }"
                :src="handleSidebarImage(section)"
                alt=""
                class="sidebar-list-item-link-icon-img"
              />         
            </span>
            <span class="sidebar-list-item-link-text" v-if="!narrow_sidebar">
              {{ section.nombre_modulo }}
            </span>
          </a>
        </li>
      </ul>
  </aside>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      trigger_tippy: 'mouseenter focus',
      print_sections: [],
      user: this.$ls.get("user"),
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      id_grupo: this.$ls.get("user")?.grupo && typeof this.$ls.get("user")?.grupo[0]=== 'object' && this.$ls.get("user")?.grupo[0]!=null? this.$ls.get("user")?.grupo[0].id_grupo : "null",
      path: "",
      active_sidebar: false,
      active_sidebar_text: false,
      hover_section: {},

      //FF VAR
      narrow_sidebar: false,
      mobile_sidebar: false,
      media_mobile: window.matchMedia("(max-width: 767px)"),
      media_table: window.matchMedia("(min-width: 768px) and (max-width: 1199px)"),
      media_desktop: window.matchMedia("(min-width: 1200px)")
    };
  },

  computed: {
    ...mapState("sidebar",["sidebar_open", "modules"]),
  },
  updated() {
    this.path = this.$route.matched[0]?.path;
  },

  async mounted() {
    await this.getModulesFromStore();
    this.path = this.$route.matched[0]?.path;
  },

  watch: {
    active_sidebar: function () {
      if (this.active_sidebar) {
        setTimeout(() => {
          this.active_sidebar_text = true;
        }, 100);
      } else {
        this.active_sidebar_text = false;
      }
    },
  },
  methods: {
    ...mapActions("sidebar",["sideAc","getModules"]),
    checkSection(section){
      if((section.url_menu_activo != '' && section.url_menu_activo != null) && (section.url_menu_inactivo != '' && section.url_menu_inactivo != null)){
        return true;
      }
      return false;
    },
    async getModulesFromStore(){
      let data ={
        id_empresa:this.id_empresa,
        id_privilegio: this.user.privilegio[0].id_tipo,
        id_grupo: this.id_grupo
      }
      await this.getModules(data);
      this.print_sections = this.modules;
    },
    goToRoute(route) {
      this.path = this.$route.matched[0].path;
      if (route == this.$route.matched[0].path) {
        return;
      } else {
        this.$router.push({ path: route }).then(() => {
          window.scrollTo(0, 0);
          this.closeSidebar();
          
        });
      }

    },
    handleSidebarImage(section) {
      if (this.path == section.moduloGeneral.ruta_admin) {
        return section.url_menu_activo;
      } else {
        if(this.hover_section == section){
          return section.url_menu_activo;
        }else{
          return section.url_menu_inactivo;
        }
      }
    },


    //FF FUNCTIONS
    narrowSidebar () {
      let main = document.getElementById("main");
      this.narrow_sidebar = !this.narrow_sidebar;
      if(this.narrow_sidebar) {
        main.classList.add('main-narrow');
      }
      else {
        main.classList.remove('main-narrow');
      }
    },
    closeSidebar () {
      let main = document.getElementById("main");
      this.narrow_sidebar = true;
      main.classList.add('main-narrow');
    },
    mediaBreakpoints () {
      let main = document.getElementById("main");
      if(!this.narrow_sidebar) {
        if(this.media_table.matches) {
          this.narrow_sidebar = true;
          main.classList.add('main-narrow');
        }
      }
      if(this.narrow_sidebar) {
        if(this.media_mobile.matches) {
          this.narrow_sidebar = false;
          main.classList.remove('main-narrow');
        }
        if(this.media_desktop.matches) {
          this.narrow_sidebar = true;
        }
      }
    },
		toggleSidebar: function () {
      this.mobile_sidebar = !this.mobile_sidebar;
		},
  },
  beforeMount() {
    window.addEventListener('resize', this.mediaBreakpoints);
  }
};
</script>
