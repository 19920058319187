import { render, staticRenderFns } from "./WidgetLayoutComponent.vue?vue&type=template&id=268a0231&scoped=true"
import script from "./WidgetLayoutComponent.vue?vue&type=script&lang=js"
export * from "./WidgetLayoutComponent.vue?vue&type=script&lang=js"
import style0 from "./WidgetLayoutComponent.vue?vue&type=style&index=0&id=268a0231&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "268a0231",
  null
  
)

export default component.exports