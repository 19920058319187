<script>
import { mapActions, mapState, mapGetters } from "vuex";
import Spinner from "../Spinner.vue";
import moment from "moment";
export default {
  props: {
    id: String,
  },
  data() {
    return {
      // Controla la visibilidad del spinner de carga
      show_spinner: false,
      // Datos básicos del canal
      channel_name: "",
      channel_description: "",
      active_button: false,
      create_mode: true,

      // Configuración de ciclos de transmisión
      transmissionDailyCycle: "1",
      transmissionHourlyCycle: "1",

      // Estados de los selectores de tiempo
      active_week: true,
      active_hours: true,

      // Control de horarios
      inicio_hora: "00",
      inicio_min: "00",
      termino_hora: "00",
      termino_min: "00",
      
      // Control de días de transmisión
      dias_frecuencias: [1, 2, 3, 4, 5, 6, 7], // 1=Lunes a 7=Domingo
      days_ok: false,
      channel_days: "",
      channel_hours: "",
      nameReq: {
        channel_name: false,
        channel_description: false,
      },
      dias: [
        { char: "L", value: 1 },
        { char: "M", value: 2 },
        { char: "X", value: 3 },
        { char: "J", value: 4 },
        { char: "V", value: 5 },
        { char: "S", value: 6 },
        { char: "D", value: 7 },
      ],
      diasHash: {
        1: "Lunes",
        2: "Martes",
        3: "Miercoles",
        4: "Jueves",
        5: "Viernes",
        6: "Sabado",
        7: "Domingo",
      },
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    this.channel_days = "1";
    this.channel_hours = "1";
    if (id != undefined) {
      this.show_spinner = true;
      if (this.listCanales.length === 0) {
        await this.GetListCanalesAction({ id_estado: 5 });
      }
      const channel = await this.getChannelById(Number(id));

      this.create_mode = false;

      this.channel_name = channel.nombre_canal;
      this.channel_description = channel.descripcion_canal;
      //TODO: Revisar

      /*
        "reproduccion_dia":1
        "reproduccion_hora":1
      */

      if (channel.reproduccion_hora == 1) {
        this.active_hours = false;
        if (!channel.frecuencia[0]) return;
        const { hora_inicio, hora_fin } = channel.frecuencia[0];
        const [hora_i_h, hora_i_m] = hora_inicio.split(":");
        const [hora_f_h, hora_f_m] = hora_fin.split(":");

        this.inicio_hora = hora_i_h;
        this.inicio_min = hora_i_m;
        this.termino_hora = hora_f_h;
        this.termino_min = hora_f_m;
      }

      if (channel.reproduccion_dia == 1) {
        this.dias_frecuencias = [];
        this.active_week = false;
        channel.frecuencia.forEach(({ num_dia }) => {
          this.dias_frecuencias.push(Number(num_dia));
        });
      }

      this.show_spinner = false;
    }
  },
  computed: {
    ...mapGetters("canalCorporativoModule", ["getChannelById"]),
    ...mapState("canalCorporativoModule", ["listCanales"]),
    channel() {
      const { id } = this.$route.params;
      return { ...this.getChannelById(id) };
    },
    channelConfig() {
      const {
        channel_name,
        transmissionDailyCycle,
        transmissionHourlyCycle,
        dias_frecuencias,
      } = this;
      return {
        channel_name,
        transmissionDailyCycle,
        transmissionHourlyCycle,
        dias_frecuencias,
      };
    },
    badTime() {
      // Valida que haya al menos 1 hora de diferencia entre inicio y fin
      if (this.active_hours) return false;
      return (
        Number(this.inicio_hora) * 60 + Number(this.inicio_min) + 59 >=
        Number(this.termino_hora) * 60 + Number(this.termino_min)
      );
    },
    active() {
      if (
        this.channel_name.trim() == "" ||
        this.channel_description.trim() == ""
      ) {
        return true;
      }
      if (this.badTime) return true;
      if (this.dias_frecuencias.length == 0) return true;
      return false;
    },
    tooltip() {
      if (
        this.channel_name.trim() == "" ||
        this.channel_description.trim() == ""
      )
        return `Debes completar los campos <br> obligatorios (*) y asegura <br> 
                que el nombre tenga <br>al menos 5 caracteres.`;
      if (this.badTime)
        return `La hora de inicio debe ser <br>menor a la hora de termino <br> 
                por minimo una hora.`;
      if (this.dias_frecuencias.length == 0)
        return `Debes seleccionar al menos <br>
                un dia de la semana.`;
      return "";
    },
  },

  methods: {
    ...mapActions("canalCorporativoModule", [
      "addCanal",
      "editCanal",
      "GetListCanalesAction",
    ]),

    /**
     * Agrega un nuevo canal con la configuración especificada
     * @async
     */
    async addChannel() {
      // Convierte las horas a formato HH:mm:ss usando moment.js
      const horaInicioDuration = moment.duration(
        `${this.inicio_hora}:${this.inicio_min}`
      );
      const horaTerminoDuration = moment.duration(
        `${this.termino_hora}:${this.termino_min}`
      );
      const horaInicioFormatted = moment
        .utc(horaInicioDuration.as("milliseconds"))
        .format("HH:mm:ss");
      const horaTerminoFormatted = moment
        .utc(horaTerminoDuration.as("milliseconds"))
        .format("HH:mm:ss");

      let data = {
        nombre_canal: this.channel_name.trim(),
        descripcion_canal: this.channel_description.trim(),
        dias_frecuencia: this.dias_frecuencias.sort(),
        hora_inicio: horaInicioFormatted,
        hora_fin: horaTerminoFormatted,
      };
      this.show_spinner = true;
      const resAdd = await this.addCanal(data);
      if (!resAdd) {
        this.$toast.open({
          message: "Falla al momento de agregar canal",
          type: "error",
          duration: 6000,
          position: "top-right",
        });
      } else {
        this.$toast.open({
          message: "Tu canal ha sido agregado correctamente",
          type: "success",
          duration: 6000,
          position: "top-right",
        });
        this.$router.push({
          name: "CanalCorporativo-canales",
        });
      }
      this.show_spinner = false;
    },

    /**
     * Actualiza la configuración de un canal existente
     * @async
     */
    async editChannel() {
      const horaInicioDuration = moment.duration(
        `${this.inicio_hora}:${this.inicio_min}`
      );
      const horaTerminoDuration = moment.duration(
        `${this.termino_hora}:${this.termino_min}`
      );
      const hora_inicio = moment
        .utc(horaInicioDuration.as("milliseconds"))
        .format("HH:mm:ss");
      const hora_fin = moment
        .utc(horaTerminoDuration.as("milliseconds"))
        .format("HH:mm:ss");

      let data = {
        nombre_canal: this.channel_name.trim(),
        descripcion_canal: this.channel_description.trim(),
        dias_frecuencia: this.dias_frecuencias.sort(),
        hora_inicio,
        hora_fin,
        id_canal: +this.$route.params.id,
      };
      this.show_spinner = true;
      const resEdit = await this.editCanal(data);
      if (!resEdit) {
        this.$toast.open({
          message:
            "Falla al momento de editar canal, favor contactar al administrador",
          type: "error",
          duration: 0,
          position: "top-right",
        });
      } else {
        this.$toast.open({
          message: "Tu canal ha sido editado correctamente",
          type: "success",
          duration: 6000,
          position: "top-right",
        });
        this.$router.push({ name: "CanalCorporativo-canales" });
      }
      this.show_spinner = false;
    },

    /**
     * Valida el formato de hora ingresado
     * @param {Event} e Evento del input
     * @param {string} status Valor actual
     * @param {string} tipo Tipo de input (inicio/termino)
     */
    selectHora(e, status, tipo) {
      let key = e.keyCode || e.charCode;
      if (key != 8) {
        //backspace pressed
        if (this.validateOnlyNumber(status)) {
          if (tipo == "inicio-clicked" || tipo == "termino-clicked") {
            if (status.length == 1) {
              if (status < 10 && status >= 1) {
                status = "0" + status;
              }
            }
            if (status.length > 2 || status == "0") {
              status == "00";
            }
          }
          if (status > 23) {
            status = "00";
          }
          if (tipo == "inicio" || tipo == "inicio-clicked") {
            this.inicio_hora = status;
          }
          if (tipo == "termino" || tipo == "termino-clicked") {
            this.termino_hora = status;
          }
        } else {
          if (tipo == "inicio") this.inicio_hora = "";
          if (tipo == "termino") this.termino_hora = "";
        }
      }
    },

    /**
     * Gestiona la selección de días de transmisión
     * @param {number} day Día seleccionado (1-7)
     */
    selectDay(day) {
      // Toggle de selección de días - añade o remueve días de la lista
      let found_index = this.dias_frecuencias.findIndex((dias) => dias == day);
      if (found_index == -1) {
        this.dias_frecuencias.push(day);
      } else {
        this.dias_frecuencias.splice(found_index, 1);
      }
    },

    highlight() {
      Object.keys(this.nameReq).forEach((name) => {
        const elemnt = document.querySelector(`[name="${name}"]`);
        if (elemnt.value == "") {
          this.nameReq[name] = true;
        } else {
          this.nameReq[name] = false;
        }
      });
    },
    cleanHighlight() {
      Object.keys(this.nameReq).forEach((name) => (this.nameReq[name] = false));
    },

    getSelectedButton(day) {
      let existe = this.dias_frecuencias.find((dias) => dias == day);
      let status = false;
      if (existe) {
        status = true;
      }
      return status;
    },

    selectMin(e, status, tipo) {
      let key = e.keyCode || e.charCode;
      if (key != 8) {
        if (this.validateOnlyNumber(status)) {
          if (tipo == "inicio-clicked" || tipo == "termino-clicked") {
            if (status.length == 1) {
              if (status < 10 && status >= 1) {
                status = "0" + status;
              }
              if (status == "0") {
                status = "00";
              }
            }
          }
          if (status > 59) {
            status = "00";
          }

          if (tipo == "inicio" || tipo == "inicio-clicked") {
            this.min_inicio = status;
          }
          if (tipo == "termino" || tipo == "termino-clicked") {
            this.min_termino = status;
          }
        } else {
          if (tipo == "inicio") this.min_inicio = "";
          if (tipo == "termino") this.min_termino = "";
        }
      }
    },

    validateOnlyNumber(text) {
      // Validación para permitir solo números en inputs
      let num_regex = /^[0-9]*$/g;
      return num_regex.test(text);
    },

    trueDays() {
      this.dias_frecuencias = [1, 2, 3, 4, 5, 6, 7];
    },
    trueHours() {
      this.inicio_hora = "00";
      this.inicio_min = "00";
      this.termino_hora = "00";
      this.termino_min = "00";
    },
  },
  components: {
    Spinner,
  },
};
</script>

<template>
  <section id="form-canal">
    <div class="row mt-3">
      <div class="col-12">
        <h3 class="section-head-title ps-0 mb-3">
          <!-- <font-awesome-icon icon="tv" class="color-secondary pe-1"/> -->
          <template v-if="create_mode">
            <strong>Crear canal:</strong> Ingresa la información de tu canal.
          </template>
          <template v-else>
            <strong>Editar canal:</strong> Edita los datos informativos de tu
            canal.
          </template>
        </h3>
        <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
          <div class="card-body d-flex flex-column mh-700">
            <div class="row flex-grow-1 mt-3">
              <div class="col-12 col-lg-5 d-flex flex-column mt-3 mt-lg-0">
                <h5 class="font-main text-secondary">Datos de tu canal</h5>
                <div class="card flex-grow-1">
                  <div class="card-body">
                    <div class="row mb-3">
                      <div class="col-12">
                        <label class="text-secondary">Nombre *</label>
                        <input
                          type="text"
                          name="channel_name"
                          class="form-control input-custom"
                          :class="{ 'is-invalid': nameReq['channel_name'] }"
                          placeholder="Ingresa el nombre de tu canal"
                          maxlength="150"
                          v-model="channel_name"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <label class="text-secondary">Descripción *</label>
                        <textarea
                          class="form-control textarea-custom"
                          placeholder="Ingresa una breve descripción del canal. Esta puede incluir detalles de su ubicación."
                          name="channel_description"
                          v-model="channel_description"
                          :class="{
                            'is-invalid': nameReq['channel_description'],
                          }"
                          maxlength="75"
                          rows="3"
                        >
                        </textarea>
                        <div class="form-text text-end">
                          {{
                            channel_description != undefined
                              ? channel_description.length
                              : "0"
                          }}/75
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 d-flex flex-column mt-3 mt-lg-0">
                <h5 class="font-main text-secondary">
                  Periodicidad de transmisión
                </h5>
                <div class="card flex-grow-1">
                  <div class="card-body">
                    <section class="row">
                      <label class="text-secondary mb-3"
                        >Selecciona los días de transmisión *</label
                      >
                      <label class="text-secondary mb-3">
                        <input
                          type="radio"
                          name="active_week"
                          :value="true"
                          @click="trueDays()"
                          v-model="active_week"
                        />
                        Toda la semana</label
                      >
                      <label class="text-secondary">
                        <input
                          type="radio"
                          name="active_week"
                          :value="false"
                          v-model="active_week"
                        />
                        Personalizado</label
                      >
                      <ul
                        class="form-days list-inline w-auto ms-4 p-2 rounded-3"
                        :class="{
                          'bg-secondary bg-opacity-25': active_week,
                        }"
                      >
                        <template v-for="dia in dias">
                          <li
                            class="list-inline-item"
                            :key="'inicio' + dia.value"
                          >
                            <label
                              class="form-days-item"
                              :class="{
                                active: dias_frecuencias.includes(dia.value),
                              }"
                            >
                              <input
                                type="checkbox"
                                :value="dia.value"
                                hidden
                                :disabled="active_week"
                                v-model="dias_frecuencias"
                                href="javascript:"
                              />
                              {{ dia.char }}
                            </label>
                          </li>
                        </template>
                      </ul>
                    </section>
                    <section class="row">
                      <label class="text-secondary mt-3"
                        >Selecciona el horario de transmisión *</label
                      >
                      <label class="text-secondary mb-3">
                        <input
                          type="radio"
                          name="active_hours"
                          :value="true"
                          @click="trueHours()"
                          v-model="active_hours"
                        />
                        24 Horas</label
                      >
                      <label class="text-secondary mb-3">
                        <input
                          type="radio"
                          name="active_hours"
                          :value="false"
                          v-model="active_hours"
                        />
                        Personalizado</label
                      >
                      <div class="col-6">
                        <label class="text-secondary mb-1">Hora inicio</label>
                        <div class="d-flex">
                          <div class="flex-grow-1">
                            <input
                              class="form-control input-custom"
                              type="number"
                              :disabled="active_hours"
                              min="0"
                              max="23"
                              v-model="inicio_hora"
                              @input="selectHora($event, inicio_hora, 'inicio')"
                              @focusout="
                                selectHora(
                                  $event,
                                  inicio_hora,
                                  'inicio-clicked'
                                )
                              "
                            />
                          </div>
                          <div
                            class="d-flex justify-content-center align-items-center px-2"
                          >
                            :
                          </div>
                          <div class="flex-grow-1">
                            <input
                              class="form-control input-custom"
                              type="number"
                              :disabled="active_hours"
                              v-model="inicio_min"
                              min="0"
                              max="59"
                              @input="selectMin($event, inicio_min, 'inicio')"
                              @focusout="
                                selectMin($event, inicio_min, 'inicio-clicked')
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <label class="text-secondary mb-1">Hora término</label>
                        <div class="d-flex">
                          <div class="flex-grow-1">
                            <input
                              class="form-control input-custom"
                              type="number"
                              :disabled="active_hours"
                              min="0"
                              max="23"
                              v-model="termino_hora"
                              @input="
                                selectHora($event, termino_hora, 'termino')
                              "
                              @focusout="
                                selectHora(
                                  $event,
                                  termino_hora,
                                  'termino-clicked'
                                )
                              "
                            />
                          </div>
                          <div
                            class="d-flex justify-content-center align-items-center px-2"
                          >
                            :
                          </div>
                          <div class="flex-grow-1">
                            <input
                              class="form-control input-custom"
                              type="number"
                              :disabled="active_hours"
                              v-model="termino_min"
                              min="0"
                              max="59"
                              @input="selectMin($event, termino_min, 'termino')"
                              @focusout="
                                selectMin(
                                  $event,
                                  termino_min,
                                  'termino-clicked'
                                )
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div
                  class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2"
                >
                  <router-link
                    class="btn btn-custom-color-white border border-round-50 mw-100 me-2"
                    :to="{ name: 'CanalCorporativo-canales' }"
                  >
                    Volver
                  </router-link>
                  <span
                    :content="tooltip"
                    v-tippy="{
                      placement: 'top',
                      arrow: true,
                      trigger: active ? 'mouseenter focus' : '',
                    }"
                    v-on:mouseover="highlight()"
                    v-on:mouseleave="cleanHighlight()"
                  >
                    <button
                      v-show="create_mode == true"
                      class="btn btn-custom-color-blue border-round-50 mw-100"
                      @click="addChannel()"
                      :disabled="active"
                    >
                      Crear
                    </button>
                  </span>
                  <span
                    :content="tooltip"
                    v-tippy="{
                      placement: 'top',
                      arrow: true,
                      trigger: active ? 'mouseenter focus' : '',
                    }"
                    v-on:mouseover="highlight()"
                    v-on:mouseleave="cleanHighlight()"
                  >
                    <button
                      v-show="create_mode == false"
                      class="btn btn-custom-color-blue border-round-50 mw-100"
                      @click="editChannel()"
                      :disabled="active"
                    >
                      Guardar
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Spinner v-show="show_spinner" />
  </section>
</template>
