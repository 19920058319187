<template>
  <section id="agregar-layout">
    <div class="row">
      <div class="col-12">
        <!-- Channel design options -->
        <div class="row my-3 mb-lg-0">
          <div class="col-6 col-lg-6">
            <div class="row mb-2">
              <div
                class="col-2 col-lg-3 d-flex align-items-center text-secondary"
              >
                Selecciona Canal
              </div>
              <div class="col-8 col-lg-9">
                <v-select
                  class="selvue-custom"
                  :options="listCanales"
                  :value="selectedCanal"
                  label="nombre_canal"
                  :clearable="false"
                  @input="openQuestionChangeCanal"
                >
                </v-select>
              </div>
            </div>
          </div>

          <div class="col-6 col-lg-6">
            <div class="row mb-2">
              <div
                class="col-2 col-lg-4 d-flex align-items-center justify-content-lg-end text-secondary"
              >
                Layout:
              </div>
              <div class="col-8 col-lg-8">
                <v-select
                  class="selvue-custom"
                  :placeholder="'Seleccionar API'"
                  :options="layouts"
                  v-model="contentPreview.layout"
                  :clearable="false"
                  @input="onChangeChannelDesign"
                >
                </v-select>
              </div>
            </div>
          </div>
        </div>

        <div class="card card-custom border-round-10 card-shadow border-0">
          <div class="card-body d-flex flex-column">
            <!-- Title -->
            <div class="row mb-0">
              <div class="col-12 col-lg-6">
                <h3 class="font-title-bold color-main">
                  Añade, edita y organiza
                </h3>
              </div>
            </div>

            <!-- Subtitle -->
            <div class="row mb-4">
              <div
                class="col-12 mb-3 mb-lg-0 d-flex align-items-center text-secondary"
              >
                Haz clic en los apartados del layout para personalizar el
                contenido del canal. Podrás ver un preview de la programación
                actual.
              </div>
            </div>

            <!-- Layout -->
            <div class="row justify-content-center">
              <div class="col-lg-12 col-xl-10 d-flex justify-content-center">
                <LayoutSinNoticia
                  v-if="contentPreview.layout?.id === 1"
                  :contentPreview="contentPreview"
                />
                <LayoutConNoticia
                  v-if="contentPreview.layout?.id === 2"
                  :contentPreview="contentPreview"
                />
                <Layout3
                  v-if="contentPreview.layout?.id === 3"
                  :contentPreview="contentPreview"
                />
                <Layout4
                  v-if="contentPreview.layout?.id === 4"
                  :contentPreview="contentPreview"
                />
                <Layout5
                  v-if="contentPreview.layout?.id === 5"
                  :contentPreview="contentPreview"
                />
              </div>

              <div class="col-lg-12 col-xl-2">
                <ColorSelection
                  :colors="background_colors"
                  :selectedColor="contentPreview.color"
                  @update:selectedColor="selectBackgroundColor"
                />
              </div>
            </div>

            <!-- Botones -->
            <div class="row">
              <div class="col-12">
                <div class="d-flex align-items-center justify-content-end">
                  <button
                    class="btn btn-custom-color-white border border-round-50 mw-100 me-2"
                    @click="backToChannel"
                  >
                    Volver
                  </button>
                  <span
                    content="Complete todos los campos <br> del layout para publicar el mural."
                    v-tippy="{
                      placement: 'top',
                      arrow: true,
                      trigger: !isLayoutValidForBack ? 'mouseenter focus' : '',
                    }"
                  >
                    <button
                      class="btn btn-custom-color-blue border-round-50 mw-100"
                      @click="addToChannel"
                      :disabled="!isLayoutValidForBack"
                    >
                      Publicar
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal modal-custom fade" id="modal-layout-logo" tabindex="-1">
      <div class="modal-dialog">
        <AgregarLogoModal
          v-show="logo_modal == true"
          @closeLogo="closeLogo"
          :saved_logo_src="contentPreview.logo"
        />
      </div>
    </div>

    <div class="modal modal-custom fade" id="modal-layout-widget" tabindex="-1">
      <div class="modal-dialog modal-xl">
        <AgregarWidget
          v-if="widget_modal == true"
          @closeWidget="closeWidget"
          :rank="selected_widget_rank"
          :widgets="widgets"
          :key="modalWidgetKey"
        />
      </div>
    </div>

    <ModalRrss
      ref="modalRrss"
      :apis="apis_rrss"
      :loaded_apis="contentPreview.apiNews"
      @addedApiNewsToChannel="closeApiNewsModal"
    />

    <Question
      v-if="open_question_modal"
      :msg="question_modal_msg"
      :hideCancel="false"
      @cancel="cancelQuestion"
      @accept="acceptQuestion"
    />

    <Spinner v-show="show_spinner" />
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
const Spinner = () => import("../Spinner.vue");
const Question = () => import("../Modales/Question.vue");
const AgregarWidget = () => import("./AgregarWidget.vue");
const AgregarLogoModal = () => import("./AgregarLogoModal.vue");
const ColorSelection = () => import("@/components/tv/ColorSelection");
const ModalRrss = () => import("@/components/tv/modals/ModalRrss.vue");

// Layout
const LayoutConNoticia = () => import("@/components/tv/LayoutConNoticia");
const LayoutSinNoticia = () => import("@/components/tv/LayoutSinNoticia");
const Layout3 = () => import("@/components/tv/Layout3");
const Layout4 = () => import("@/components/tv/Layout4");
const Layout5 = () => import("@/components/tv/Layout5");

export default {
  components: {
    AgregarLogoModal,
    AgregarWidget,
    Question,
    Spinner,
    ColorSelection,
    LayoutConNoticia,
    LayoutSinNoticia,
    Layout3,
    Layout4,
    Layout5,
    ModalRrss,
  },

  data() {
    return {
      open_question_modal: false,
      question_modal_msg:
        "Al cambiar de canal se perderán los cambios que no hayas guardado. ¿Deseas continuar?",
      show_spinner: false,
      logo_modal: false,
      widget_modal: false,
      background_colors: [],

      widgets: [],
      widget_data: [
        { text: "Widget" },
        { text: "Widget" },
        { text: "Widget" },
        { text: "Widget" },
      ],
      selected_widget_rank: "",
      unsubscribe: false,
      contentPreview: {},

      modalWidgetKey: 0,
      thumbnailDimensions: {
        width: 640,
        height: 360,
      },
      selectedCanal: {
        nombre_canal: '',
        id_canal: null,
        estado: { id_estado: null },
      },
      //FF
      date: new Date(),
      layouts: [
        { id: 1, label: "Sin RSS" },
        { id: 2, label: "Con RSS" },
        { id: 3, label: "Sin banners" },
        { id: 4, label: "Banner horizontal" },
        { id: 5, label: "Sólo video y banner" },
      ],

      apis_rrss: [],
    };
  },

  computed: {
    ...mapState("tvCorporativaModule", ["channelLayout"]),
    ...mapState("canalCorporativoModule", ["listCanales"]),
    ...mapGetters("tvCorporativaModule", ["isLayoutValidForBack"]),

    showApiNews() {
      return this.contentPreview.layout?.id === 2;
    },
  },

  async created() {
    await Promise.all([
      this.getPaletaColores(),
      this.getAllWidgets(),
      this.getApisRrss(),
      this.getCanales()
    ]);
    this.cleanSelectedMediaList();
  },

  async mounted() {
    let channelLayoutLS = localStorage.getItem("channelLayout");
    if (channelLayoutLS !== null && channelLayoutLS !== undefined) {
      channelLayoutLS = JSON.parse(channelLayoutLS);
    }

    if (this.channelLayout.id_canal != this.$route.params.id) {
      this.resetChannelContent();

      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        this.contentPreview = this.getContentPreview(
          state.tvCorporativaModule.channelLayout
        );
        const channelLayout = JSON.stringify(
          state.tvCorporativaModule.channelLayout
        );
        localStorage.setItem("channelLayout", channelLayout);
      });
    }
    if (this.$route.name.includes("edit")) {
      if (`${this.channelLayout.id_canal}` != `${this.$route.params.id}`) {
        await this.getSavedChannelContent();
      }
    } else {
      this.setChannelLayoutId(this.$route.params.id);
      if (!this.channelLayout.id_layout)
        this.setChannelLayoutDesign(this.layouts[0]);
    }

    this.contentPreview = this.getContentPreview(this.channelLayout);

    this.selectedCanal = this.listCanales.find(
      (c) => `${c.id_canal}` === `${this.channelLayout.id_canal}`
    );
    
  },
  methods: {
    ...mapActions("tvCorporativaModule", [
      "setChannelLayoutColor",
      "setChannelLayoutId",
      "setChannelLayout",
      "setChannelLayoutDesign",
      "resetChannelContent",
      "getCanal",
      "getWidgets",
      "getNoticiasOpciones",
      "cleanSelectedMediaList",
    ]),
    ...mapActions("canalCorporativoModule", [
      "GetListCanalesAction",
      "getPaletaColoresAction",
    ]),

    async getPaletaColores() {
      this.background_colors = await this.getPaletaColoresAction();
      if (!this.background_colors) {
        this.toastGenericError();
      }
    },

    goToSelectedCanal() {
      if (this.selectedCanal.estado.id_estado === 2) {
        this.$router.push({
          name: "CanalCorporativo-addContenido",
          params: { id: this.selectedCanal.id_canal },
        });
      } else {
        this.$router.push({
          name: "CanalCorporativo-editContenido",
          params: { id: this.selectedCanal.id_canal },
        });
      }
    },

    acceptQuestion() {
      this.goToSelectedCanal();
    },

    openQuestionChangeCanal(selectedCanal) {
      this.selectedCanal = selectedCanal;
      this.open_question_modal = true;
    },
    cancelQuestion() {
      this.open_question_modal = false;
    },

    async getCanales() {
      this.show_spinner = true;
      await this.GetListCanalesAction({ id_estado: 5 });
      this.show_spinner = false;
    },

    /**
     * Maneja el cambio de diseño del canal
     * @param {Object} selectedLayout Layout seleccionado
     */
    onChangeChannelDesign(selectedLayout) {
      this.setChannelLayoutDesign(selectedLayout);
    },

    /**
     * Genera la vista previa del layout basado en el estado del canal
     * @param {Object} channelState Estado del canal (colores, logo, videos, etc)
     * @returns {Object} Objeto con la configuración de vista previa formateada
     */
    getContentPreview(channelState) {
      const {
        color,
        logo,
        url_logo,
        videos,
        banners,
        bannersH,
        apiNews,
        widgets,
      } = channelState;
      return {
        layout:
          this.layouts.find(
            (layoutObj) => layoutObj.id == this.channelLayout.id_layout
          ) || this.layouts[0],
        color,
        logo: logo.src ? logo.base64 : url_logo,
        videoImg: videos.length
          ? videos[0].url_archivo || videos[0].url_video
          : null,
        bannerImg: banners.length ? banners[0].url_archivo : null,
        bannerHImg: bannersH.length ? bannersH[0].url_archivo : null,
        apiNews: apiNews,
        widgets: [...Array(4).keys()].map((i) => widgets[i] ?? null),
        numBanners: banners.length,
        numBannersH: bannersH.length,
        numVideos: videos.length,
        activeVideoPage: 1,
        activeBannerPage: 1,
        activeBannerHPage: 1,
      };
    },

    changeDisplayedVideo() {
      const newPage = this.contentPreview.activeVideoPage;
      this.contentPreview.videoImg =
        this.channelLayout.videos[newPage - 1].url_archivo ||
        this.channelLayout.videos[newPage - 1].url_video;
    },
    changeDisplayedBanner() {
      const newPage = this.contentPreview.activeBannerPage;
      this.contentPreview.bannerImg =
        this.channelLayout.banners[newPage - 1].url_archivo;
    },
    changeDisplayedBannerH() {
      const newPage = this.contentPreview.activeBannerHPage;
      this.contentPreview.bannerHImg =
        this.channelLayout.bannersH[newPage - 1].url_archivo;
    },

    /**
     * Obtiene el contenido guardado del canal para edición
     * @async
     */
    async getSavedChannelContent() {
      this.show_spinner = true;
      const payload = {
        idCanal: +this.$route.params.id,
      };
      await this.getCanal(payload);
      this.show_spinner = false;
    },

    async getAllWidgets() {
      this.show_spinner = true;
      this.widgets = await this.getWidgets();
      this.widgets = this.widgets.filter(
        (w) => !(w.id_objeto === 4 && w.tipo_widget === "2")
      );
      if (!this.widgets) {
        this.toastGenericError();
        return;
      }
      this.show_spinner = false;
    },

    async getApisRrss() {
      this.show_spinner = true;
      this.apis_rrss = await this.getNoticiasOpciones();
      if (!this.apis_rrss) {
        this.toastGenericError();
        return;
      }
      this.show_spinner = false;
    },

    reconfigureThumbnailDimensions() {
      this.$nextTick(() => {
        const element = this.$refs.layoutVideoContainer;
        this.thumbnailDimensions.width = element.offsetWidth;
      });
    },

    addLogo() {
      this.logo_modal = true;
    },
    closeLogo() {
      this.logo_modal = false;
      this.contentPreview.logo = this.channelLayout.logo.base64;
    },
    addVideo() {
      this.$router.push({
        name: "CanalCorporativo-listadoVideos",
        params: { id: this.$route.params.id },
      });
    },
    addBanner() {
      this.$router.push({
        name: "CanalCorporativo-listadoBanners",
        params: { id: this.$route.params.id },
      });
    },
    addBannerH() {
      this.$router.push({
        name: "CanalCorporativo-listadoBannersHorizontal",
        params: { id: this.$route.params.id },
      });
    },
    addWidget(index) {
      this.modalWidgetKey++;
      this.selected_widget_rank = String(index);
      this.widget_modal = true;
    },
    closeWidget() {
      this.contentPreview.widgets = this.channelLayout.widgets;
      this.widget_modal = false;
    },
    selectBackgroundColor(color) {
      this.contentPreview.color = color;
      this.setChannelLayoutColor(color);
    },
    backToChannel() {
      this.$router.push({
        name: "CanalCorporativo-canales",
      });
    },

    /**
     * Guarda los cambios realizados en el layout del canal
     * Valida widgets requeridos y APIs de noticias antes de guardar
     * @async
     */  
    async addToChannel() {
      if ([1, 2, 3].includes(+this.channelLayout.id_layout)) {
        if (this.isWidgetListIncomplete()) {
          this.toastWidgetsIncomplete();
          return;
        }

        // TODO: a isLayoutDataValid (abstrae de isNewsSelected blabla)
        if (this.areApiNewsSelected()) {
          this.toastApiNewsNotSelected();
          return;
        }
      }

      this.show_spinner = true;
      const data = {};
      if (this.channelLayout.id_lista) {
        Object.assign(data, { id_lista: +this.channelLayout.id_lista });
      }

      const res = await this.setChannelLayout(data);
      this.show_spinner = false;
      if (!res) {
        this.toastAddContentError();
      } else {
        this.toastAddContentSuccess();
        this.resetChannelContent();
        this.$router.push({ name: "CanalCorporativo-canales" });
      }

      if (this.unsubscribe != false) {
        // Desuscribe a los cambios en la store
        this.unsubscribe();
      }
    },

    closeApiNewsModal() {
      this.$refs.modalRrss.closeModal();
    },

    /**
     * Valida si la lista de widgets está incompleta según el layout
     * @returns {boolean} true si faltan widgets requeridos
     */
    isWidgetListIncomplete() {
      const widgets_num = Object.keys(this.channelLayout.widgets).length;
      return widgets_num < (this.channelLayout.id_layout == 3 ? 2 : 4);
    },

    areApiNewsSelected() {
      if (this.showApiNews) {
        return this.contentPreview.apiNews.length === 0;
      } else {
        return false;
      }
    },

    toastApiNewsNotSelected() {
      this.$toast.open({
        message: "Debes seleccionar un sitio de noticias.",
        type: "warning",
        duration: 6000,
        position: "top-right",
      });
    },

    toastWidgetsIncomplete() {
      this.$toast.open({
        message: `Debes seleccionar los ${
          this.channelLayout.id_layout == 3 ? 2 : 4
        } widgets.`,
        type: "warning",
        duration: 6000,
        position: "top-right",
      });
    },

    toastAddContentError() {
      this.$toast.open({
        message: "Falla al momento de agregar contenido al canal.",
        type: "error",
        duration: 6000,
        position: "top-right",
      });
    },

    toastAddContentSuccess() {
      this.$toast.open({
        message: "Tu contenido ha sido agregado correctamente.",
        type: "success",
        duration: 6000,
        position: "top-right",
      });
    },
  },

  provide() {
    return {
      addLogo: this.addLogo,
      addVideo: this.addVideo,
      addBanner: this.addBanner,
      addBannerH: this.addBannerH,
      addWidget: this.addWidget,
      changeDisplayedVideo: this.changeDisplayedVideo,
      changeDisplayedBanner: this.changeDisplayedBanner,
      changeDisplayedBannerH: this.changeDisplayedBannerH,
    };
  },
};
</script>

<style scope lang="scss">
.layout-monitor-bottom-widget-active {
  background-color: var(--color);
}

.layout-monitor-bottom-widget-active:hover {
  background-color: var(--color-hover);
}
</style>
