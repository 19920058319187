<script>
import { mapActions, mapState, mapGetters } from "vuex";
import Spinner from "../Spinner.vue";
import Status from "../Modales/Status.vue";
export default {
  components: { Spinner, Status },

  data() {
    return {
      error: false,
      show_spinner: false,
      accion: "Crear un tramo",
      tipo_list: ["Aéreo", "Terrestre"],
      // seleccionar transporte
      transportes_lista: [],
      transporte: "",
      // tipo de viaje
      viaje_list: ["Solo ida", "Ida y vuelta"],
      viaje: "",
      modal_status_msg: "",
      modal_status: false,
      open_modal_status: false,

      userInput: {
        nombreOrigen: {
          value: "",
          error: false,
        },
        nombreDestino: {
          value: "",
          error: false,
        },
        idMedio: {
          value: "",
          error: false,
        },
        idaVuelta: {
          value: "",
          error: false,
        },
        tipo: {
          value: "",
          error: false,
        },
        idDestino: {
          value: "",
          error: false,
        },
        // campos no modificables por el usuario
        isConexion: {
          value: false,
        },
      },
    };
  },

  watch: {
    userInput: {
      handler(val) {
        this.$emit("userInputChange", val);
      },
      deep: true,
    },
  },

  computed: {
    ...mapState("transporteModule", ["transportes_list", "editingTramo"]),
    ...mapGetters("transporteModule", ["transporteSegunTipo"]),
  },

  async created() {
    this.$emit("userInputChange", this.userInput);

    const params = {
      id_tipo: "0",
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
    };
    this.show_spinner = true;
    await this.getTransportesList(params);
    this.show_spinner = false;

    // Si solo hay un tipo en la lista, seleccionarlo automáticamente
    //TODO: revisar si es necesario
    if (this.tipo_list.length === 1) {
      this.userInput.tipo.value = this.tipo_list[0];
      this.selectTipo(this.tipo_list[0]);
    }

    this.transportes_lista = this.transporteSegunTipo();

    if (Object.keys(this.editingTramo).length !== 0) {
      this.setDataForEditingTramo();
    }

    //  en caso de que no hayan transportes
    if (this.transportes_list.length == 0) {
      this.open_modal_status = true;
      this.modal_status = false;
      this.modal_status_msg =
        "Error al desplegar formulario, no hay transportes para seleccionar";
    }
  },

  methods: {
    ...mapActions("transporteModule", [
      "getTransportesList",
      "cleanEditingTramoAction",
    ]),

    setDataForEditingTramo() {
      this.accion = "Edita tramo";
      this.userInput.tipo.value =
        this.editingTramo.transporte_tipo_medio.transporte_tipo.nombre_transporte;
      this.userInput.nombreOrigen.value = this.editingTramo.nombre_origen;
      this.userInput.nombreDestino.value = this.editingTramo.nombre_destino;
      this.transporte = this.editingTramo.transporte_tipo_medio.nombre_medio;
      this.userInput.idMedio.value =
        this.editingTramo.transporte_tipo_medio.id_medio;
      this.userInput.idDestino.value = this.editingTramo.id_destino;
      this.userInput.idaVuelta.value = this.editingTramo.ida_vuelta;
      // no modificables por el usuario:
      this.userInput.isConexion.value = this.editingTramo.is_conexion;
      if (this.userInput.idaVuelta.value == "1") {
        this.viaje = "Solo ida";
        this.userInput.idaVuelta.value = "1";
      }
      if (this.userInput.idaVuelta.value == "2") {
        this.viaje = "Ida y vuelta";
        this.userInput.idaVuelta.value = "2";
      }
    },

    closeModal() {
      this.cleanEditingTramoAction();
      this.$emit("close-modal");
    },

    selectTipo(tipo) {
      this.transportes_lista = this.transporteSegunTipo(tipo);
      this.transporte = "";
      this.userInput.idMedio.value = "";
    },

    selectTransporte(transporte) {
      this.userInput.idMedio.value = transporte.id_medio;
      this.userInput.tipo.value = transporte.transporte_tipo.nombre_transporte;
    },

    selectViaje(viaje) {
      if (viaje == "Solo ida") {
        this.userInput.idaVuelta.value = "1";
      } else if (viaje == "Ida y vuelta") {
        this.userInput.idaVuelta.value = "2";
      }
    },

    acceptStatus() {
      this.$router.push("./transportes");
    },
  },
};
</script>

<template>
  <div id="form-tramo">
    <div class="modal-header">
      <h1 class="modal-title font-main-bold color-main fs-5">
        {{ accion }}
      </h1>
      <button
        type="button"
        class="modal-custom-close"
        data-bs-dismiss="modal"
        @click="closeModal()"
        aria-label="Close"
      >
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <label class="text-secondary"> Tipo de transporte </label>
          <v-select
            class="selvue-custom mb-3"
            :class="userInput.tipo.error ? 'form-error' : ''"
            :options="tipo_list"
            v-model="userInput.tipo.value"
            @input="selectTipo(userInput.tipo.value)"
            :clearable="false"
          >
          </v-select>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6 mb-3">
          <label class="text-secondary"> Ciudad de origen </label>
          <input
            class="form-control input-custom"
            :class="userInput.nombreOrigen.error ? 'form-error' : ''"
            maxlength="30"
            type="text"
            v-model="userInput.nombreOrigen.value"
          />
        </div>
        <div class="col-12 col-lg-6 mb-3">
          <label class="text-secondary"> Ciudad de destino </label>
          <input
            class="form-control input-custom"
            :class="userInput.nombreDestino.error ? 'form-error' : ''"
            maxlength="30"
            type="text"
            v-model="userInput.nombreDestino.value"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6">
          <label class="text-secondary"> Transportes </label>
          <v-select
            class="selvue-custom mb-3"
            :class="userInput.idMedio.error ? 'form-error' : ''"
            placeholder="Seleccionar"
            label="nombre_medio"
            :options="transportes_lista"
            v-model="transporte"
            @input="selectTransporte(transporte)"
            :clearable="false"
          >
          </v-select>
        </div>
        <div class="col-12 col-lg-6">
          <label class="text-secondary"> Tipo de viaje </label>
          <v-select
            class="selvue-custom mb-3"
            :class="userInput.idaVuelta.error ? 'form-error' : ''"
            placeholder="Seleccionar"
            v-model="viaje"
            :options="viaje_list"
            @input="selectViaje(viaje)"
            :clearable="false"
          >
          </v-select>
        </div>
      </div>
      <Status
        v-if="open_modal_status"
        :msg="modal_status_msg"
        :status="modal_status"
        @close="acceptStatus"
      />
      <Spinner v-if="show_spinner" />
    </div>
  </div>
</template>