<template>
  <div class="card card-shadow card-bars border-round-10">
    <div class="card-body">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1 me-3">
          <div class="card-bars-count-num pe-2">{{ pantallas }}</div>
          <div class="card-bars-count-val">{{ opciones.title }}</div>
        </div>
        <div class="flex-shrink-0">
          <img
            v-show="opciones.estado == '0'"
            class="img-obj-60"
            src="@/assets/img/tv/registradas.svg"
          />
          <img
            v-show="opciones.estado == '1'"
            class="img-obj-60"
            src="@/assets/img/tv/sin_registrar.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pantallas: {
      type: String,
    },
    opciones: {
      type: Object,
    },
  },
};
</script>
