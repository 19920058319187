<template>
  <a
    @click="addWidget(index)"
    href="javascript:"
    data-bs-toggle="modal"
    data-bs-target="#modal-layout-widget"
    class="widget-container"
    :class="{ 'widget-container-active': color != '' }"
    :style="{ '--color': color, '--color-hover': `${darkenHexColor(color)}` }"
  >
    <p
      v-if="!widget"
      class="mb-0 d-flex flex-column flex-lg-row align-items-center text-secondary"
      :class="{ 'text-white': color != '' }"
    >
      <span class="btn-add">
        <AddEditLayoutButton add media="widget" />
      </span>
    </p>
    <div
      v-else
      class="widget-container-content d-flex flex-column align-items-center"
    >
      <div class="d-flex align-items-center">
        <div class="flex-shrink-0">
          <img
            class="widget-container-content-img"
            :src="widget.imagen_widget"
          />
        </div>
        <div
          class="flex-grow-1 ms-1"
          style="text-align: center; width: min-content"
        >
          <!-- <div class="flex-grow-1 ms-1"> -->
          <span
            class="widget-container-content-title"
            :class="{ 'text-white': color != '' }"
          >
            {{ widget.nombre_widget }}
          </span>
        </div>
      </div>

      <span class="btn-edit mt-lg-2">
        <AddEditLayoutButton edit media="widget" />
      </span>
    </div>
  </a>
</template>

<script>
import AddEditLayoutButton from "./AddEditLayoutButton.vue";

export default {
  components: {
    AddEditLayoutButton,
  },

  props: {
    widget: Object,
    color: String,
    index: Number,
  },
  inject: ["addWidget"],
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/_variables.scss";

.btn {
  &-add {
  }
  &-edit {
  }
}

.widget-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 100%;
  width: 100%;
  color: lighten($black, 20%);
  &-content {
    &-img {
      max-width: 32px;
    }
    &-title {
      font-size: 16px;
    }
  }
  &-active {
    background-color: var(--color);
    &:hover {
      background-color: var(--color-hover);
    }
  }
}
</style>
